.ova-service-list {
	width: 100%;
    
    .text {
        margin: 0;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.5;
		color: var(--heading);
		padding-bottom: 15px;
		margin-bottom: 20px;
		position: relative;
		&:before {
			position: absolute;
			left: 0;
			bottom: 0;
			content: "";
			width: 100%;
			height: 1px;
			background: #e9e9e9;
		}
	}

	.service-title-list {
		list-style: none;
		margin: 10px 0 0;
		padding: 0;

		li.item {
			padding: 0;
			margin-bottom: 8px;

			a {
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-weight: 800;
				font-size: 16px;
				padding: 16px 30px;
				line-height: 1.2;
				color: var(--heading);
				background-color: #f5f5f5;
				-webkit-transition: all .3s ease;
				-moz-transition: all .3s ease;
				-o-transition: all .3s ease;
				transition: all .3s ease;
				i {
					color: var(--primary);
					font-size: 11px;
				}
			}

			&:hover, &.active {
				a {
					-webkit-transition: all .3s ease;
					-moz-transition: all .3s ease;
					-o-transition: all .3s ease;
					transition: all .3s ease;
					background-color: var(--heading);
					color: #fff;
				}
			}

		}
	}
}