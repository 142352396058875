.ova-service-box-template1 {
	transition: all .3s ease;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	position: relative;
	background-color: #fff;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
	border: 1px solid transparent;
	padding: 60px;
	z-index: 1;
	text-align: center;
	overflow: hidden;
	@media (max-width: 520px) {
		padding: 40px 30px;
	}

	 &:hover {
    	transition: all 0.3s ease;
    	border: 1px solid var(--primary);
    	.mask {
    		transition: all 0.5s ease;
    		opacity: 1; 
    	}
    	.img-service {
    		transition: all 0.5s ease;
    		transform: translateY(5px);
    	}
    }

    .mask {
    	transition: all 0.5s ease;
    	position: absolute;
    	content: "";
    	width: 100%;
    	height: 100%;
    	left: 0;
    	top: 0;
    	opacity: 0;
		background-position: top left;
        background-size: auto;
        background-repeat: no-repeat;
        opacity: 0.25;
    }

    .img-service {
    	transition: all 0.5s ease;
    	position: relative;
    	display: inline-block;
    	margin-bottom: 48px;
    	img {
    		transition: all 0.5s ease;
    		width: 198px;
    		height: 198px;
    		border-radius: 50%;
    		display: block;
    		object-fit: cover;
    	}
    	.icon {
			transition: all 0.5s ease;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			bottom: -30px;
			left: 50%;
			transform: translateX(-50%); 
			width: 90px;
			height: 90px;
			border-radius: 50%;
			background-color: var(--primary);
	        i {
	        	transition: all 0.3s ease;
	        	display: inline-flex;
	         	font-size: 52px;
	         	color: #fff;
	        }
		}
    }

    .info {
    	a {
    		.title {
                &:hover {
	       			transition: all 0.3s ease;
	       		    color: var(--primary);
	       		}
    		}
    	}
        .title {
        	position: relative;
			z-index: 1;
			transition: all 0.3s ease;
			margin: 0;
			padding-bottom: 25px; 
	        font-weight: 400;
			font-size: 24px;
			line-height: 1.2;
		}

		.description {
			margin: 0 0 25px 0;
			font-size: 16px;
			font-weight: 500;
			padding-bottom: 30px;
			border-bottom: 1px solid rgb(240, 232, 232);
		}

		.book-button {
			transition: all 0.3s ease;
			position: relative;
			z-index: 1;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			letter-spacing: 0.5px;
			font-weight: 600;
			color: var(--heading);
			text-transform: uppercase;
			line-height: 1;
			&:hover {
				transition: all 0.3s ease;
				color: var(--primary);
			}
			i {
				font-size: 15px;
				line-height: 1;
				margin-left: 8px;
			}
		}
    }

}

// template2
.ova-service-box-template2 {
	transition: all 0.3s ease;
	text-align: left;
	background-color: #fff;
	transition: all 0.3s ease;
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 50px;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
	background-color: #fff;
	border: 1px solid transparent;
	@media (max-width: 520px) {
		padding: 30px;
	}

    &:hover {
    	transition: all 0.3s ease;
    	border: 1px solid var(--primary);
    	.mask {
    		transition: all 0.5s ease;
    		bottom: 0;
    		opacity: 1; 
    	}
    	.icon {
            i {
            	transition: all 0.5s ease;
             	transform: translateY(-5px);
            }
    	}
    }

    .mask {
    	transition: all 0.5s ease;
    	position: absolute;
    	content: "";
    	width: 100%;
    	height: 100%;
    	left: 0;
    	bottom: -30px;
    	opacity: 0;
		background-position: bottom right;
        background-size: auto;
        background-repeat: no-repeat;
    }

	.icon {
		position: relative;
		display: inline-flex;
	    i {
	    	transition: all 0.5s ease;
	    	transform: translateY(0);
	    	display: inline-flex;
	   	    font-size: 66px;
	   	    color: var(--primary);
	    }
	}
    
    a{
    	.title {
            &:hover {
				transition: all 0.3s ease;
				color: var(--primary);
			}
    	}
    }
	.title {
		transition: all 0.3s ease;
		position: relative;
		z-index: 1;
		font-size: 20px;
		margin: 6px 0 23px 0;
		font-weight: 400;
		color: var(--heading);
	}

	.description {
	    position: relative;
		font-size: 16px;
		font-weight: 500;
		margin: 0;
		color: var(--text);
		z-index: 1;
	}
}